// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-cutilier-service-category-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/cutilier/ServiceCategoryTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-cutilier-service-category-template-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-cutilier-service-item-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/cutilier/ServiceItemTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-cutilier-service-item-template-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-cutilier-team-member-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/cutilier/TeamMemberTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-cutilier-team-member-template-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-become-an-ishdoll-jsx": () => import("./../../../src/pages/become-an-ishdoll.jsx" /* webpackChunkName: "component---src-pages-become-an-ishdoll-jsx" */),
  "component---src-pages-bridal-jsx": () => import("./../../../src/pages/bridal.jsx" /* webpackChunkName: "component---src-pages-bridal-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-fabulish-rewards-jsx": () => import("./../../../src/pages/fabulish-rewards.jsx" /* webpackChunkName: "component---src-pages-fabulish-rewards-jsx" */),
  "component---src-pages-happy-hour-specials-jsx": () => import("./../../../src/pages/happy-hour-specials.jsx" /* webpackChunkName: "component---src-pages-happy-hour-specials-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-massage-membership-jsx": () => import("./../../../src/pages/massage-membership.jsx" /* webpackChunkName: "component---src-pages-massage-membership-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-salon-guidelines-jsx": () => import("./../../../src/pages/salon-guidelines.jsx" /* webpackChunkName: "component---src-pages-salon-guidelines-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-the-platform-jsx": () => import("./../../../src/pages/the-platform.jsx" /* webpackChunkName: "component---src-pages-the-platform-jsx" */)
}

